import { memo } from "react";
import { PAGES } from "../../consts";

interface ErrorPageProps {
  title: string;
  description: string;
}

const ErrorPage: React.FC<ErrorPageProps> = ({ title, description }) => {
  return (
    <div className="text-center">
      <h1 className="mt-14 text-3xl font-bold tracking-tight text-white sm:text-5xl">
        {title}
      </h1>
      <p className="mt-8 text-base font-semibold text-white">{description}</p>
      <div className="mt-10 flex items-center justify-center gap-x-6">
        <a
          href={PAGES.HOME}
          className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Go back home
        </a>
      </div>
    </div>
  );
};

export default memo(ErrorPage);
