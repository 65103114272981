import { useEffect } from "react";
import useApiKey from "../hooks/useApiKey";
import { useNavigate } from "react-router-dom";
import { PAGES } from "../consts";

const SignOut: React.FC = () => {
  const navigate = useNavigate();
  const { setApiKey } = useApiKey();

  useEffect(() => {
    setApiKey("");
    navigate(PAGES.LOGIN);
  }, [navigate, setApiKey]);

  return <></>;
};

export default SignOut;
