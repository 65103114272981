import { useMemo, useState } from "react";
import { useSelectedSportContext } from "../../context/SelectedSportProvider";
import useGames, { Game } from "../../hooks/useGames";
import GameOverview from "./GameOverview";
import { GAME_PROPS } from "../../hooks/useProps";

const GameListOverview: React.FC = () => {
  const { selectedSportKey } = useSelectedSportContext();
  const { games } = useGames(selectedSportKey);
  const [selectedTabLeft, setSelectedTabLeft] = useState<string>(
    GAME_PROPS.MONEY_LINE_KEY
  );
  const [selectedTabRight, setSelectedTabRight] = useState<string>("upcoming");

  const onSelectTabLeft = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedTabLeft(e.target.value);
  };

  const onSelectTabRight = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedTabRight(e.target.value);
  };

  const { upcomingGames, finishedGames } = useMemo(() => {
    const { upcomingGames, finishedGames } = games.reduce(
      (acc, game) => {
        if (new Date(game.start_time) >= new Date()) {
          acc.upcomingGames.push(game);
        } else {
          acc.finishedGames.push(game);
        }

        return acc;
      },
      { upcomingGames: [], finishedGames: [] } as {
        upcomingGames: Game[];
        finishedGames: Game[];
      }
    );

    return {
      upcomingGames,
      finishedGames: finishedGames.reverse(),
    };
  }, [games]);

  const tabsLeft = [
    {
      key: GAME_PROPS.MONEY_LINE_KEY,
      name: "Money Line",
    },
    {
      key: GAME_PROPS.SPREAD_KEY,
      name: "Spread",
    },
    {
      key: GAME_PROPS.TOTALS_KEY,
      name: "Total",
    },
  ];

  const tabsRight = [
    {
      key: "upcoming",
      name: "Upcoming",
    },
    {
      key: "finished",
      name: "Finished",
    },
  ];

  return (
    <div className="flex flex-col gap-4 mt-4">
      <div className="border-b border-white-200 pb-5 sm:pb-0">
        <h3 className="text-3xl font-semibold leading-6 text-white">Games</h3>
        <div className="mt-5 sm:mt-6">
          <div className="sm:hidden">
            <div className="flex gap-3">
              <div>
                <label htmlFor="current-tab" className="sr-only">
                  Select a prop
                </label>
                <select
                  id="current-tab"
                  name="current-tab"
                  className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  value={selectedTabLeft}
                  onChange={onSelectTabLeft}
                >
                  {tabsLeft.map((tab) => (
                    <option key={tab.key}>{tab.name}</option>
                  ))}
                </select>
              </div>
              <div>
                <label htmlFor="current-tab" className="sr-only">
                  Select games
                </label>
                <select
                  id="current-tab"
                  name="current-tab"
                  className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  value={selectedTabRight}
                  onChange={onSelectTabRight}
                >
                  {tabsRight.map((tab) => (
                    <option key={tab.key}>{tab.name}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="hidden sm:block">
            <div className="w-full flex justify-between">
              <nav className="-mb-px flex space-x-8">
                {tabsLeft.map((tab) => (
                  <div
                    key={tab.name}
                    className={`
                    ${
                      tab.key === selectedTabLeft
                        ? "border-indigo-500 text-indigo-600"
                        : "border-transparent text-gray-300 hover:border-gray-100 hover:text-gray-100"
                    }
                   cursor-pointer whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium`}
                    aria-current={
                      tab.key === selectedTabLeft ? "page" : undefined
                    }
                    onClick={() => setSelectedTabLeft(tab.key)}
                  >
                    {tab.name}
                  </div>
                ))}
              </nav>
              <nav className="-mb-px flex space-x-8">
                {tabsRight.map((tab) => (
                  <div
                    key={tab.name}
                    className={`
                    ${
                      tab.key === selectedTabRight
                        ? "border-indigo-500 text-indigo-600"
                        : "border-transparent text-gray-300 hover:border-gray-100 hover:text-gray-100"
                    }
                   cursor-pointer whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium`}
                    aria-current={
                      tab.key === selectedTabRight ? "page" : undefined
                    }
                    onClick={() => setSelectedTabRight(tab.key)}
                  >
                    {tab.name}
                  </div>
                ))}
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div>
        {(selectedTabRight === "upcoming" ? upcomingGames : finishedGames).map(
          (game) => (
            <GameOverview key={game.id} game={game} propKey={selectedTabLeft} />
          )
        )}
      </div>
    </div>
  );
};

export default GameListOverview;
