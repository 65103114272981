import { Prop } from "../hooks/useProps";
import { getApiKey } from "../utils/auth";
import { PropsRoute } from "./routes";

export const QueryKey = "props";

export const fetchProps = async (): Promise<Prop[]> => {
  return fetch(`${process.env.REACT_APP_SERVER_URL}${PropsRoute}/list`, {
    headers: { Authorization: `Bearer ${getApiKey()}` },
  })
    .then(async (res) => {
      const data = await res.json();

      if (res.ok) {
        return data;
      } else {
        return [];
      }
    })
    .catch(() => {
      return [];
    });
};
