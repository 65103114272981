import { memo } from "react";
import { Data } from "../../hooks/useData";
import { Prop } from "../../hooks/useProps";
import { getDataPointValue } from "../../utils/data";
import { valueFormatter } from "../../utils/tremor";

interface PropDataProps {
  prop: Prop;
  dataPoint: Data;
}

const PropData: React.FC<PropDataProps> = ({ prop, dataPoint }) => {
  const primaryDataPoint = getDataPointValue(prop.primary_data, dataPoint);
  const secondaryDataPoint = prop.show_both_values
    ? getDataPointValue(
        prop.primary_data === "point" ? "price" : "point",
        dataPoint
      )
    : undefined;

  if (primaryDataPoint === undefined) {
    return <>No Data</>;
  }

  return (
    <>
      <span className="font-semibold">{valueFormatter(primaryDataPoint)}</span>{" "}
      {secondaryDataPoint !== undefined && (
        <span className="font-semibold">
          ({valueFormatter(secondaryDataPoint)})
        </span>
      )}
    </>
  );
};

export default memo(PropData);
