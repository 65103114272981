import { useEffect, useMemo, useState } from "react";
import useData, { DataType } from "../../hooks/useData";
import { Game } from "../../hooks/useGames";
import { GAME_PROPS, Prop } from "../../hooks/useProps";
import DetailedChart from "../Graphs/DetailedChart";
import PropDataTable from "../Tables/PropDataTable";
import PropData from "../Data/PropData";

interface GamePropProps {
  game: Game;
  prop: Prop;
}

const GameProp: React.FC<GamePropProps> = ({ game, prop }) => {
  const [selectedOutcome, setSelectedOutcome] = useState<string>(
    prop.key === GAME_PROPS.TOTALS_KEY ? "Over" : game.home_team
  );
  const { data, isLoading } = useData(game.id, prop.key);
  const [selectedValueType, setSelectedValueType] = useState<DataType>(
    prop.primary_data
  );

  const dataFilteredByOutcome = useMemo(
    () => data.filter((d) => d.outcome_name === selectedOutcome),
    [data, selectedOutcome]
  );

  const reversedData = useMemo(
    () => [...dataFilteredByOutcome].reverse(),
    [dataFilteredByOutcome]
  );

  const mostRecentDataPoint = reversedData?.length ? reversedData[0] : null;
  const openingDataPoint = dataFilteredByOutcome?.length
    ? dataFilteredByOutcome[0]
    : null;

  const handleOnSelectOutcome = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOutcome(e.target.value);
  };

  useEffect(() => {
    setSelectedOutcome(
      prop.key === GAME_PROPS.TOTALS_KEY ? "Over" : game.home_team
    );
  }, [prop, game.home_team]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div className="w-full flex justify-between flex-wrap">
        {prop.key !== GAME_PROPS.TOTALS_KEY && (
          <select
            value={selectedOutcome}
            onChange={handleOnSelectOutcome}
            className="mt-2 block w-[300px] rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
          >
            <option value={game.home_team}>{game.home_team}</option>
            <option value={game.away_team}>{game.away_team}</option>
          </select>
        )}
      </div>
      <div className="w-full flex justify-between items-center flex-wrap">
        <div className="flex flex-col">
          <h3 className="text-white text-lg mt-2">
            {prop.key !== GAME_PROPS.TOTALS_KEY && selectedOutcome}{" "}
            {mostRecentDataPoint && (
              <PropData prop={prop} dataPoint={mostRecentDataPoint} />
            )}
            {openingDataPoint && (
              <h3 className="text-white text-sm italic">
                Opening line{" "}
                <PropData prop={prop} dataPoint={openingDataPoint} />
              </h3>
            )}
          </h3>
        </div>
        {data.some((d) => d.price !== undefined && d.point !== undefined) && (
          <div className="border-b border-white-200 pb-5 mb-3 sm:pb-0 flex justify-between">
            <nav className="-mb-px flex space-x-8">
              <div
                key="point"
                className={`
                    ${
                      selectedValueType === "point"
                        ? "border-indigo-500 text-indigo-600"
                        : "border-transparent text-gray-300 hover:border-gray-100 hover:text-gray-100"
                    }
                   cursor-pointer whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium`}
                aria-current={
                  selectedValueType === "point" ? "page" : undefined
                }
                onClick={() => setSelectedValueType("point")}
              >
                Line
              </div>
              <div
                key="price"
                className={`
                    ${
                      selectedValueType === "price"
                        ? "border-indigo-500 text-indigo-600"
                        : "border-transparent text-gray-300 hover:border-gray-100 hover:text-gray-100"
                    }
                   cursor-pointer whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium`}
                aria-current={
                  selectedValueType === "price" ? "page" : undefined
                }
                onClick={() => setSelectedValueType("price")}
              >
                Price
              </div>
            </nav>
          </div>
        )}
      </div>
      <div className="py-4">
        <DetailedChart
          data={data}
          outcome_name={selectedOutcome}
          plot_type={selectedValueType}
        />
      </div>
      <PropDataTable data={reversedData} prop={prop} />
    </div>
  );
};

export default GameProp;
