export const formatDate = (date: Date): string => {
  const month = getMonthShortForm(date);
  const day = date.getDate();

  return `${month} ${day}`;
};

export const formatTime = (date: Date): string => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const amOrPm = hours >= 12 ? "PM" : "AM";

  // Convert hours from 24-hour to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  // Format minutes
  const minutesStr = minutes > 0 ? ":" + String(minutes).padStart(2, "0") : "";

  // Construct the formatted time string
  return `${hours}${minutesStr} ${amOrPm}`;
};

export const getMonthShortForm = (date: Date): string => {
  const month = date.getMonth();

  switch (month) {
    case 0:
      return "Jan";
    case 1:
      return "Feb";
    case 2:
      return "Mar";
    case 3:
      return "Apr";
    case 4:
      return "May";
    case 5:
      return "Jun";
    case 6:
      return "Jul";
    case 7:
      return "Aug";
    case 8:
      return "Sep";
    case 9:
      return "Oct";
    case 10:
      return "Nov";
    case 11:
      return "Dec";
    default:
      return "";
  }
};

export const roundToNearestMinute = (date: Date): Date => {
  const msPerMinute = 1000 * 60;
  const roundedTimeStamp =
    Math.round(date.getTime() / msPerMinute) * msPerMinute;
  return new Date(roundedTimeStamp);
};
