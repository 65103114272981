import Navbar from "../components/Core/Navbar";
import GameListOverview from "../components/Dashboard/GameListOverview";
import withAuthorization from "../hoc/withAuthorization";

const Dashboard: React.FC = () => {
  return (
    <div className="h-full min-h-[100vh] bg-gray-900 ">
      <Navbar />
      <div className="px-6 pt-6">
        <GameListOverview />
      </div>
    </div>
  );
};

export default withAuthorization(Dashboard);
