import { useParams } from "react-router-dom";
import GameComponent from "../components/Game";
import Navbar from "../components/Core/Navbar";
import Redirect from "../components/Redirect";
import { PAGES } from "../consts";

const Game: React.FC = () => {
  const { id } = useParams();

  if (!id) {
    return <Redirect to={PAGES.HOME} />;
  }

  return (
    <div className="h-full min-h-[100vh] bg-gray-900 text-white">
      <Navbar />
      <div className="px-6 pt-6">
        <GameComponent id={id} />
      </div>
    </div>
  );
};

export default Game;
