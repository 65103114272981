import { useMemo } from "react";
import { Game } from "../../../hooks/useGames";
import useData, { Data } from "../../../hooks/useData";
import PlayerPropOverview from "./PlayerPropOverview";

interface PlayerPropsListOverviewProps {
  game: Game;
  propKey: string;
}

const PlayerPropsListOverview: React.FC<PlayerPropsListOverviewProps> = ({
  game,
  propKey,
}) => {
  const { data } = useData(game.id, propKey);

  const outcomes = useMemo(() => {
    return data.reduce((acc, dataPoint) => {
      const label = dataPoint.outcome_name;

      if (acc[label]) {
        acc[label].push(dataPoint);
      } else {
        acc[label] = [dataPoint];
      }

      return acc;
    }, {} as { [label: string]: Data[] });
  }, [data]);

  return (
    <div>
      {Object.entries(outcomes).map(([label, data]) => (
        <PlayerPropOverview
          key={label}
          game={game}
          data={data}
          propKey={propKey}
          label={label}
        />
      ))}
      {Object.values(outcomes).length === 0 && (
        <div className="text-white w-full text-center mt-16">
          <p className="font-semibold text-3xl">No Data</p>
          <p className="text-xl mt-2">Please check back soon for more data!</p>
        </div>
      )}
    </div>
  );
};

export default PlayerPropsListOverview;
