import { Sport, SportDataCount } from "../hooks/useSports";
import { getApiKey } from "../utils/auth";
import { SportsDataCountRoute, SportsRoute } from "./routes";

export const QueryKey = "sports";
export const SportsDataQueryKey = "sports-data";

export const fetchSports = async (): Promise<Sport[]> => {
  return fetch(`${process.env.REACT_APP_SERVER_URL}${SportsRoute}`, {
    headers: { Authorization: `Bearer ${getApiKey()}` },
  })
    .then(async (res) => {
      const data = await res.json();

      if (res.ok) {
        return data;
      } else {
        return [];
      }
    })
    .catch(() => {
      return [];
    });
};

export const fetchSportsDataCount = async (): Promise<SportDataCount> => {
  return fetch(`${process.env.REACT_APP_SERVER_URL}${SportsDataCountRoute}`, {
    headers: { Authorization: `Bearer ${getApiKey()}` },
  })
    .then(async (res) => {
      const data = await res.json();

      if (res.ok) {
        return data;
      } else {
        return {};
      }
    })
    .catch(() => {
      return {};
    });
};
