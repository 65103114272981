import { LineChart } from "@tremor/react";
import { Data, DataType } from "../../hooks/useData";
import { memo, useMemo } from "react";
import { formatDate, formatTime, roundToNearestMinute } from "../../utils/time";
import { valueFormatter } from "../../utils/tremor";
import { getDataPointValue } from "../../utils/data";

interface OverviewChartProps {
  data: Data[];
  outcome_name?: string;
  reverseColors?: boolean;
  variant?: "sm" | "md";
  plot_type: DataType;
}

const OverviewChart: React.FC<OverviewChartProps> = ({
  data,
  outcome_name,
  variant,
  plot_type,
}) => {
  const height = variant === "md" ? "h-[260px]" : "h-[160px]";

  const filteredData = useMemo(
    () =>
      outcome_name ? data.filter((d) => d.outcome_name === outcome_name) : data,
    [data, outcome_name]
  );

  const { chartData, categories } = useMemo(() => {
    const tempData: { [key: string]: string | number }[] = [];
    const categories: string[] = [];

    for (const dataPoint of filteredData) {
      if (!categories.includes(dataPoint.outcome_name)) {
        categories.push(dataPoint.outcome_name);
      }

      const roundedTimestamp = roundToNearestMinute(
        new Date(dataPoint.timestamp)
      );
      const time = `${formatDate(roundedTimestamp)} ${formatTime(
        roundedTimestamp
      )}`;

      const foundDataPoint = tempData.find((dp) => dp.time === time);

      if (foundDataPoint) {
        foundDataPoint[dataPoint.outcome_name] =
          getDataPointValue(plot_type, dataPoint) ?? 0;
      } else {
        const newDataPoint = {
          time,
          [dataPoint.outcome_name]:
            getDataPointValue(plot_type, dataPoint) ?? 0,
        };

        tempData.push(newDataPoint);
      }
    }

    return { chartData: tempData, categories };
  }, [filteredData, plot_type]);

  const { minDataValue, maxDataValue } = useMemo(() => {
    const dataValues = filteredData.map(
      (d) => getDataPointValue(plot_type, d) ?? 0
    );
    return {
      minDataValue: Math.min(...dataValues),
      maxDataValue: Math.max(...dataValues),
    };
  }, [filteredData, plot_type]);

  const avgValue = useMemo(
    () => (minDataValue + maxDataValue) / 2,
    [minDataValue, maxDataValue]
  );

  return (
    <div
      className={`${height} relative bg-indigo-600 bg-opacity-10 rounded-xl`}
    >
      <div
        className={`absolute h-[1px] bg-white bg-opacity-70 w-full ${
          variant === "md" ? "top-[130px]" : "top-[80px]"
        }`}
      >
        <p className="text-white text-sm mt-[-20px] ml-2">
          {valueFormatter(Math.round(avgValue))}
        </p>
      </div>
      <LineChart
        className="h-full"
        data={chartData}
        index="time"
        showXAxis={false}
        showYAxis={false}
        showLegend={false}
        showGridLines={false}
        yAxisWidth={65}
        minValue={minDataValue - 5}
        maxValue={maxDataValue + 5}
        categories={categories}
        colors={["lime"]}
        valueFormatter={valueFormatter}
        curveType="step"
      />
    </div>
  );
};

export default memo(OverviewChart);
