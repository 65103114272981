import { AreaChart } from "@tremor/react";
import { Data, DataType } from "../../hooks/useData";
import { memo, useMemo, useState } from "react";
import { formatDate, formatTime, roundToNearestMinute } from "../../utils/time";
import { valueFormatter } from "../../utils/tremor";
import { subHours } from "date-fns";
import { getDataPointValue } from "../../utils/data";

interface DetailedChartProps {
  data: Data[];
  outcome_name?: string;
  reverseColors?: boolean;
  plot_type: DataType;
}

type TimeFrame = "24h" | "12h" | "6h" | "1h" | "All";

const TimeFrameOptions: { label: TimeFrame }[] = [
  {
    label: "24h",
  },
  {
    label: "12h",
  },
  {
    label: "6h",
  },
  {
    label: "1h",
  },
  {
    label: "All",
  },
];

const DetailedChart: React.FC<DetailedChartProps> = ({
  data,
  outcome_name,
  plot_type,
}) => {
  const [selectedTimeFrame, setSelectedTimeFrame] = useState<TimeFrame>("All");

  const minDate = useMemo(() => {
    const now = new Date();
    switch (selectedTimeFrame) {
      case "24h":
        return subHours(now, 24);
      case "12h":
        return subHours(now, 12);
      case "6h":
        return subHours(now, 6);
      case "1h":
        return subHours(now, 1);
      case "All":
      default:
        return undefined;
    }
  }, [selectedTimeFrame]);

  const filteredData = useMemo(() => {
    const filteredByOutcome = outcome_name
      ? data.filter((d) => d.outcome_name === outcome_name)
      : data;

    return minDate
      ? filteredByOutcome.filter(
          (d) => new Date(d.timestamp).getTime() >= minDate.getTime()
        )
      : filteredByOutcome;
  }, [data, outcome_name, minDate]);

  const { chartData, categories } = useMemo(() => {
    const tempData: { [key: string]: string | number }[] = [];
    const categories: string[] = [];

    for (const dataPoint of filteredData) {
      if (!categories.includes(dataPoint.outcome_name)) {
        categories.push(dataPoint.outcome_name);
      }

      const dataPointValue = getDataPointValue(plot_type, dataPoint) ?? 0;
      const roundedTimestamp = roundToNearestMinute(
        new Date(dataPoint.timestamp)
      );
      const time = `${formatDate(roundedTimestamp)} ${formatTime(
        roundedTimestamp
      )}`;

      const foundDataPoint = tempData.find((dp) => dp.time === time);

      if (foundDataPoint) {
        foundDataPoint[dataPoint.outcome_name] = dataPointValue;
      } else {
        const newDataPoint = {
          time,
          [dataPoint.outcome_name]: dataPointValue,
        };

        tempData.push(newDataPoint);
      }
    }

    return { chartData: tempData, categories };
  }, [filteredData, plot_type]);

  const { minDataValue, maxDataValue } = useMemo(() => {
    const dataValues = filteredData.map(
      (d) => getDataPointValue(plot_type, d) ?? 0
    );
    return {
      minDataValue: Math.min(...dataValues),
      maxDataValue: Math.max(...dataValues),
    };
  }, [filteredData, plot_type]);

  return (
    <>
      <AreaChart
        className="h-[500px]"
        data={chartData}
        index="time"
        showXAxis={true}
        showYAxis={true}
        showLegend={false}
        showGridLines={true}
        yAxisWidth={65}
        categories={categories}
        colors={["lime"]}
        minValue={minDataValue - 10}
        maxValue={maxDataValue + 10}
        valueFormatter={valueFormatter}
        curveType="step"
        showAnimation={true}
        animationDuration={400}
      />
      <label className="sr-only">Select time frame</label>
      <span className="isolate inline-flex rounded-md shadow-sm ml-0 md:ml-[35px] mt-3">
        {TimeFrameOptions.map((option, i) => (
          <button
            type="button"
            className={`${i === 0 && "rounded-l-md"} ${
              i === TimeFrameOptions.length - 1 && "rounded-r-md"
            } ${
              option.label === selectedTimeFrame
                ? "bg-[#84cc16] ring-[#87d117]"
                : "bg-white hover:bg-gray-50 ring-gray-300"
            } relative inline-flex items-center px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset focus:z-10`}
            onClick={() => setSelectedTimeFrame(option.label)}
          >
            {option.label}
          </button>
        ))}
      </span>
    </>
  );
};

export default memo(DetailedChart);
