import { useQuery } from "react-query";
import { QueryKey as GamesQueryKey, fetchGame } from "../api/games";
import { Game } from "./useGames";
import { useMemo } from "react";

interface UseGameInterface {
  game: Game | null;
  isLoading: boolean;
}

const useGame = (id: string): UseGameInterface => {
  const { isLoading, data } = useQuery(
    [GamesQueryKey, id],
    () => fetchGame(id),
    {
      staleTime: 1000 * 60 * 5, // 5 minutes
    }
  );

  const game = useMemo(() => data ?? null, [data]);

  return {
    isLoading,
    game,
  };
};

export default useGame;
