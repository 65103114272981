import { QueryClient, QueryClientProvider } from "react-query";
import { AlertProvider } from "./context/AlertContext/AlertContextProvider";
import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import SignOut from "./pages/SignOut";
import { PAGES } from "./consts";
import Game from "./pages/Game";
import { SelectedSportProvider } from "./context/SelectedSportProvider";
import PlayerPropOverview from "./pages/PlayerPropOverview";
import PlayerProp from "./pages/PlayerProp";

const queryClient = new QueryClient();

function App() {
  const router = createBrowserRouter([
    {
      path: PAGES.HOME,
      element: <Dashboard />,
    },
    {
      path: `${PAGES.GAME}/:id`,
      element: <Outlet />,
      children: [
        {
          path: "",
          element: <Game />,
        },
        {
          path: "player",
          element: <Outlet />,
          children: [
            {
              path: "",
              element: <PlayerPropOverview />,
            },
            {
              path: ":prop/:outcome",
              element: <PlayerProp />,
            },
          ],
        },
      ],
    },
    {
      path: PAGES.LOGIN,
      element: <Login />,
    },
    {
      path: PAGES.SIGN_OUT,
      element: <SignOut />,
    },
  ]);

  return (
    <QueryClientProvider client={queryClient}>
      <AlertProvider>
        <SelectedSportProvider>
          <RouterProvider router={router} />
        </SelectedSportProvider>
      </AlertProvider>
    </QueryClientProvider>
  );
}

export default App;
