import { useParams } from "react-router-dom";
import PlayerPropComponent from "../components/Player/PlayerProp";
import Navbar from "../components/Core/Navbar";
import Redirect from "../components/Redirect";
import { PAGES } from "../consts";

const PlayerProp: React.FC = () => {
  const { id, prop, outcome } = useParams();

  if (!id || !prop || !outcome) {
    return <Redirect to={PAGES.HOME} />;
  }

  return (
    <div className="h-full min-h-[100vh] bg-gray-900 text-white">
      <Navbar />
      <div className="px-6 pt-6">
        <PlayerPropComponent gameId={id} propKey={prop} outcome={outcome} />
      </div>
    </div>
  );
};

export default PlayerProp;
