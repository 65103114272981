import { useQuery } from "react-query";
import { QueryKey as GamesQueryKey, fetchGames } from "../api/games";
import { useMemo } from "react";

export type Game = {
  id: string;
  sport_key: string;
  sport_title: string;
  start_time: string;
  home_team: string;
  away_team: string;
};

interface UseGamesInterface {
  games: Game[];
  isLoading: boolean;
}

const useGames = (sportKey?: string): UseGamesInterface => {
  const { isLoading, data } = useQuery(
    [GamesQueryKey, sportKey],
    () => (sportKey ? fetchGames(sportKey) : []),
    {
      staleTime: 1000 * 60 * 5, // 5 minutes
    }
  );

  const games = useMemo(() => data ?? [], [data]);

  return {
    isLoading,
    games,
  };
};

export default useGames;
