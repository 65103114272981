import { useQuery } from "react-query";
import {
  SportsDataQueryKey,
  QueryKey as SportsQueryKey,
  fetchSports,
  fetchSportsDataCount,
} from "../api/sports";
import { useCallback, useMemo } from "react";

export type Sport = {
  key: string;
  label: string;
};

export type SportDataCount = {
  [sportKey: string]: number;
};

interface UseSportsInterface {
  sports: Sport[];
  isLoading: boolean;
  sportsDataCount: SportDataCount;
  isLoadingSportsDataCount: boolean;
  getSport: (id?: string) => Sport | undefined;
}

const useSports = (): UseSportsInterface => {
  const { isLoading, data } = useQuery(SportsQueryKey, fetchSports, {
    staleTime: Infinity,
  });

  const sports = useMemo(() => data ?? [], [data]);

  const { isLoading: isLoadingSportsDataCount, data: sportsDataCountData } =
    useQuery(SportsDataQueryKey, fetchSportsDataCount, {
      staleTime: Infinity,
    });

  const sportsDataCount = useMemo(
    () => sportsDataCountData ?? {},
    [sportsDataCountData]
  );

  const getSport = useCallback(
    (key?: string) => {
      if (!key) return undefined;
      return sports.find((p) => p.key === key);
    },
    [sports]
  );

  return {
    isLoading,
    sports,
    isLoadingSportsDataCount,
    sportsDataCount,
    getSport,
  };
};

export default useSports;
