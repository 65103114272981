import { Data } from "../../hooks/useData";
import { Prop } from "../../hooks/useProps";
import { getDataPointValue } from "../../utils/data";
import { valueFormatter } from "../../utils/tremor";

interface PropDataTableProps {
  data: Data[];
  prop?: Prop;
}

const PropDataTable: React.FC<PropDataTableProps> = ({ data, prop }) => {
  const showPoint = data.some((d) => d.point !== undefined);
  const showPrice = data.some((d) => d.price !== undefined);

  return (
    <div className="py-4 mt-5 mb-10">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold leading-6 text-gray-50">
              Chart Data
            </h1>
            <p className="mt-2 text-sm text-gray-50">
              The table below shows the raw data for the chart rendered above.
            </p>
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-50 sm:pl-0"
                    >
                      Timestamp
                    </th>
                    {showPoint && (
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-50"
                      >
                        Line
                      </th>
                    )}
                    {showPrice && (
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-50"
                      >
                        Price
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {data.map((dataPoint) => (
                    <tr key={dataPoint.id}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-50 sm:pl-0">
                        {new Date(dataPoint.timestamp).toLocaleString()}
                      </td>
                      {showPoint && (
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-50">
                          {valueFormatter(
                            getDataPointValue("point", dataPoint) ?? NaN
                          )}
                        </td>
                      )}
                      {showPrice && (
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-50">
                          {valueFormatter(
                            getDataPointValue("price", dataPoint) ?? NaN
                          )}
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropDataTable;
