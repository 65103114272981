import {
  createContext,
  memo,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import useSports from "../hooks/useSports";

export interface SelectedSportContextInterface {
  selectedSportKey?: string;
  setSelectedSportKey: (newKey: string) => void;
}

export const SelectedSportContext =
  createContext<SelectedSportContextInterface>(
    {} as SelectedSportContextInterface
  );

interface SelectedSportProviderProps {
  children: ReactNode;
}

export const SelectedSportProvider: React.FC<SelectedSportProviderProps> = memo(
  ({ children }) => {
    const { sports } = useSports();
    const [selectedSportKey, setSelectedSportKey] = useState<
      string | undefined
    >(undefined);

    useEffect(() => {
      if (sports.length) {
        setSelectedSportKey(sports[0].key);
      }
    }, [sports]);

    return (
      <SelectedSportContext.Provider
        value={{ selectedSportKey, setSelectedSportKey }}
      >
        {children}
      </SelectedSportContext.Provider>
    );
  }
);

export const useSelectedSportContext = (): SelectedSportContextInterface =>
  useContext(SelectedSportContext);
