import { Game } from "../hooks/useGames";
import { getApiKey } from "../utils/auth";
import { GamesRoute } from "./routes";

export const QueryKey = "games";

export type AddGameDto = {
  id: string;
  sport: string;
  startTime: Date;
  homeTeam: string;
  awayTeam: string;
  pullPlayerProps: boolean;
};

export const fetchGame = async (id: string): Promise<Game | null> => {
  return fetch(`${process.env.REACT_APP_SERVER_URL}${GamesRoute}/${id}`, {
    headers: { Authorization: `Bearer ${getApiKey()}` },
  })
    .then(async (res) => {
      const data = await res.json();

      if (res.ok) {
        return data;
      } else {
        return null;
      }
    })
    .catch((err) => {
      console.log("ERROR");
      console.log(err);
      return null;
    });
};

export const fetchGames = async (sportKey: string): Promise<Game[]> => {
  return fetch(
    `${process.env.REACT_APP_SERVER_URL}${GamesRoute}/list/${sportKey}`,
    {
      headers: { Authorization: `Bearer ${getApiKey()}` },
    }
  )
    .then(async (res) => {
      const data = await res.json();

      if (res.ok) {
        return data;
      } else {
        return [];
      }
    })
    .catch(() => {
      return [];
    });
};

export const addGame = async (addGameDto: AddGameDto): Promise<void> => {
  return fetch(`${process.env.REACT_APP_SERVER_URL}${GamesRoute}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${getApiKey()}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(addGameDto),
  })
    .then(async (res) => {
      const data = await res.json();

      if (res.ok) {
        return data;
      } else {
        return null;
      }
    })
    .catch(() => {
      return null;
    });
};

export const deleteGame = async (id: string): Promise<boolean> => {
  return fetch(`${process.env.REACT_APP_SERVER_URL}${GamesRoute}/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${getApiKey()}`,
    },
  })
    .then(async (res) => {
      if (res.ok) {
        return true;
      } else {
        return false;
      }
    })
    .catch(() => {
      return false;
    });
};
