export const valueFormatter = function (number: number): string {
  if (isNaN(number)) {
    return "No data";
  }

  return (
    (number > 0 ? "+" : "") +
    new Intl.NumberFormat("us").format(number).toString()
  );
};
