import { Data } from "../../../hooks/useData";
import { FiChevronRight } from "react-icons/fi";
import OverviewChart from "../../Graphs/OverviewChart";
import { Link } from "react-router-dom";
import { PAGES } from "../../../consts";
import { memo, useMemo } from "react";
import { Game } from "../../../hooks/useGames";
import useProp from "../../../hooks/useProp";
import PropData from "../../Data/PropData";
import ErrorPage from "../../Core/ErrorPage";

interface PlayerPropOverviewProps {
  game: Game;
  data: Data[];
  label: string;
  propKey: string;
}

const PlayerPropOverview: React.FC<PlayerPropOverviewProps> = ({
  game,
  data,
  label,
  propKey,
}) => {
  const { prop, isLoading } = useProp(propKey);

  const mostRecentDataPoint = useMemo(() => {
    return data.length ? data[data.length - 1] : null;
  }, [data]);

  const openingDataPoint = useMemo(() => {
    return data.length ? data[0] : null;
  }, [data]);

  if (isLoading) {
    return <>Loading...</>;
  } else if (!prop) {
    return (
      <ErrorPage
        title="An error occurred"
        description="Please refresh the page and try again."
      />
    );
  }

  return (
    <div className="flex flex-col md:flex-row gap-6 items-center justify-between border-b py-3 mb-2 border-gray-200">
      <div className="w-full md:w-auto md:grow flex gap-6">
        <div className="grow">
          <Link
            to={`${PAGES.GAME}/${
              game.id
            }/player/${propKey}/${encodeURIComponent(label)}`}
            className="w-full"
          >
            <div className="w-full flex items-center justify-between">
              <div className="flex flex-col">
                <h3 className="text-white text-lg">
                  {label}{" "}
                  {mostRecentDataPoint && (
                    <PropData prop={prop} dataPoint={mostRecentDataPoint} />
                  )}
                </h3>
                {openingDataPoint && (
                  <h3 className="text-white text-sm italic">
                    Opening line{" "}
                    <PropData prop={prop} dataPoint={openingDataPoint} />
                  </h3>
                )}
              </div>
            </div>
          </Link>
          <div className="py-4">
            <OverviewChart
              data={data}
              outcome_name={label}
              plot_type={prop.primary_data}
            />
          </div>
        </div>
        <Link
          to={`${PAGES.GAME}/${game.id}/player/${propKey}/${encodeURIComponent(
            label
          )}`}
          className="cursor-pointer text-white hidden md:flex gap-1 items-center"
        >
          <p>View</p>
          <FiChevronRight />
        </Link>
      </div>
      <div className="flex md:hidden justify-end w-full">
        <Link
          to={`${PAGES.GAME}/${game.id}/player/${propKey}/${encodeURIComponent(
            label
          )}`}
          className="cursor-pointer flex text-white gap-1 items-center"
        >
          <p>View</p>
          <FiChevronRight />
        </Link>
      </div>
    </div>
  );
};

export default memo(PlayerPropOverview);
