import { useEffect, useMemo, useState } from "react";
import useData, { DataType } from "../../../hooks/useData";
import { Game } from "../../../hooks/useGames";
import DetailedChart from "../../Graphs/DetailedChart";
import PropDataTable from "../../Tables/PropDataTable";
import useProps, { Prop } from "../../../hooks/useProps";
import { formatDate, formatTime } from "../../../utils/time";
import { useNavigate } from "react-router-dom";
import { PAGES } from "../../../consts";
import PropData from "../../Data/PropData";

interface PlayerPropProps {
  game: Game;
  prop: Prop;
  outcome: string;
}

const PlayerProp: React.FC<PlayerPropProps> = ({ game, prop, outcome }) => {
  const navigate = useNavigate();
  const { playerProps } = useProps();
  const { data, isLoading } = useData(game.id, prop.key);
  const [selectedProp, setSelectedProp] = useState<string>(prop.key);
  const [selectedOutcome, setSelectedOutcome] = useState<string>(
    outcome ? decodeURIComponent(outcome) : ""
  );
  const [selectedValueType, setSelectedValueType] = useState<DataType>(
    prop.primary_data
  );

  const filteredPlayerProps = useMemo(
    () =>
      game && playerProps
        ? playerProps.filter((p) => p.sports?.includes(game.sport_key))
        : [],
    [game, playerProps]
  );

  const outcomes = useMemo(
    () => Array.from(new Set(data.map((d) => d.outcome_name))),
    [data]
  );

  const dataFilteredByOutcome = useMemo(
    () => data.filter((d) => d.outcome_name === selectedOutcome),
    [data, selectedOutcome]
  );

  const reversedData = useMemo(
    () => [...dataFilteredByOutcome].reverse(),
    [dataFilteredByOutcome]
  );

  const mostRecentDataPoint = reversedData?.length ? reversedData[0] : null;
  const openingDataPoint = dataFilteredByOutcome?.length
    ? dataFilteredByOutcome[0]
    : null;

  const handleOnSelectOutcome = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOutcome(e.target.value);
  };

  const handleOnSelectProp = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedProp(e.target.value);
  };

  useEffect(() => {
    navigate(
      `${PAGES.GAME}/${game.id}/player/${selectedProp}/${selectedOutcome}`
    );
  }, [game, selectedProp, selectedOutcome, navigate]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="flex flex-col w-full justify-center items-center mt-10">
        <p className="mb-3 text-2xl leading-6">
          {selectedOutcome} {prop.label}
        </p>
        <p className="mb-3 text-xl leading-6">
          {game.away_team} @ {game.home_team}
        </p>
        <div className="text-base flex gap-4 leading-6">
          <p>{formatDate(new Date(game.start_time))}</p>
          <p>{formatTime(new Date(game.start_time))}</p>
        </div>
      </div>
      <div className="flex flex-col w-full px-0 md:px-10 gap-4">
        <div className="w-full flex justify-between flex-wrap">
          <select
            value={selectedProp}
            onChange={handleOnSelectProp}
            className="mt-2 block w-[300px] rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
          >
            {filteredPlayerProps.map((playerProp) => (
              <option value={playerProp.key}>{playerProp.label}</option>
            ))}
          </select>
          <select
            value={selectedOutcome}
            onChange={handleOnSelectOutcome}
            className="mt-2 block w-[300px] rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
          >
            {outcomes.map((outcome) => (
              <option value={outcome}>{outcome}</option>
            ))}
          </select>
        </div>
        <div className="w-full flex justify-between items-center flex-wrap">
          <div className="flex flex-col">
            {mostRecentDataPoint && (
              <h3 className="text-white text-lg mt-2">
                {selectedOutcome}{" "}
                <PropData prop={prop} dataPoint={mostRecentDataPoint} />
              </h3>
            )}
            {openingDataPoint && (
              <h3 className="text-white text-sm italic">
                Opening line{" "}
                <PropData prop={prop} dataPoint={openingDataPoint} />
              </h3>
            )}
          </div>
          {data.some((d) => d.point !== undefined && d.price !== undefined) &&
            !prop.overValue && (
              <div className="border-b border-white-200 pb-5 mb-3 sm:pb-0 flex justify-between">
                <nav className="-mb-px flex space-x-8">
                  <div
                    key="point"
                    className={`
                    ${
                      selectedValueType === "point"
                        ? "border-indigo-500 text-indigo-600"
                        : "border-transparent text-gray-300 hover:border-gray-100 hover:text-gray-100"
                    }
                   cursor-pointer whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium`}
                    aria-current={
                      selectedValueType === "point" ? "page" : undefined
                    }
                    onClick={() => setSelectedValueType("point")}
                  >
                    Line
                  </div>
                  <div
                    key="price"
                    className={`
                    ${
                      selectedValueType === "price"
                        ? "border-indigo-500 text-indigo-600"
                        : "border-transparent text-gray-300 hover:border-gray-100 hover:text-gray-100"
                    }
                   cursor-pointer whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium`}
                    aria-current={
                      selectedValueType === "price" ? "page" : undefined
                    }
                    onClick={() => setSelectedValueType("price")}
                  >
                    Price
                  </div>
                </nav>
              </div>
            )}
        </div>
        <div className="py-4">
          <DetailedChart
            data={data}
            outcome_name={selectedOutcome}
            plot_type={selectedValueType}
          />
        </div>
        <PropDataTable data={reversedData} prop={prop} />
      </div>
    </>
  );
};

export default PlayerProp;
