import { getApiKey } from "../utils/auth";
import { DataRoute } from "./routes";

export const QueryKey = "data";

export const fetchData = async (
  gameId: string,
  propKey: string
): Promise<any> => {
  return fetch(
    `${process.env.REACT_APP_SERVER_URL}${DataRoute}/${gameId}/${propKey}`,
    {
      headers: { Authorization: `Bearer ${getApiKey()}` },
    }
  )
    .then(async (res) => {
      const data = await res.json();

      if (res.ok) {
        return data;
      } else {
        return [];
      }
    })
    .catch(() => {
      return [];
    });
};
