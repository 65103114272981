import { Link } from "react-router-dom";
import { PAGES } from "../../../consts";
import useGame from "../../../hooks/useGame";
import { FiChevronLeft } from "react-icons/fi";
import useProps from "../../../hooks/useProps";
import { useEffect, useMemo, useState } from "react";
import PlayerPropsListOverview from "./PlayerPropsListOverview";
import ErrorPage from "../../Core/ErrorPage";

interface PlayerPropsProps {
  gameId: string;
}

const PlayerProps: React.FC<PlayerPropsProps> = ({ gameId }) => {
  const { playerProps } = useProps();
  const { game, isLoading } = useGame(gameId);
  const [selectedProp, setSelectedProp] = useState<string>("");

  const filteredPlayerProps = useMemo(
    () =>
      game && playerProps
        ? playerProps.filter((p) => p.sports?.includes(game.sport_key))
        : [],
    [game, playerProps]
  );

  const tabs = useMemo(
    () => filteredPlayerProps.map((p) => ({ key: p.key, name: p.label })),
    [filteredPlayerProps]
  );

  useEffect(() => {
    if (tabs?.length) {
      setSelectedProp(tabs[0].key);
    }
  }, [tabs]);

  if (isLoading) {
    return <div>Loading</div>;
  } else if (!game) {
    return (
      <ErrorPage
        title="Game not found"
        description="Sorry, we couldn't find the game you're looking for."
      />
    );
  }

  return (
    <>
      <div className="absolute">
        <Link
          to={`${PAGES.GAME}/${gameId}`}
          className="cursor-pointer flex gap-1 items-center"
        >
          <FiChevronLeft />
          <p>Back</p>
        </Link>
      </div>
      <div className="flex flex-col w-full mt-10">
        <p className="mb-3 text-2xl font-semibold leading-6">Player props</p>
      </div>
      <div className="border-b border-white-200 pb-5 mb-3 sm:pb-0 w-full overflow-x-scroll flex justify-between">
        <nav className="-mb-px flex space-x-8">
          {tabs.map((tab) => (
            <div
              key={tab.name}
              className={`
                    ${
                      tab.key === selectedProp
                        ? "border-indigo-500 text-indigo-600"
                        : "border-transparent text-gray-300 hover:border-gray-100 hover:text-gray-100"
                    }
                   cursor-pointer whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium`}
              aria-current={tab.key === selectedProp ? "page" : undefined}
              onClick={() => setSelectedProp(tab.key)}
            >
              {tab.name}
            </div>
          ))}
        </nav>
      </div>
      <div className="flex flex-col w-full px-0 md:px-10 gap-4">
        <PlayerPropsListOverview game={game} propKey={selectedProp} />
      </div>
    </>
  );
};

export default PlayerProps;
