import useData from "../../hooks/useData";
import { Game } from "../../hooks/useGames";
import { formatDate, formatTime } from "../../utils/time";
import { FiChevronRight } from "react-icons/fi";
import OverviewChart from "../Graphs/OverviewChart";
import { Link } from "react-router-dom";
import { PAGES } from "../../consts";
import { memo, useMemo, useState } from "react";
import useProp from "../../hooks/useProp";
import PropData from "../Data/PropData";
import ErrorPage from "../Core/ErrorPage";

interface GameOverviewProps {
  game: Game;
  propKey: string;
}

const GameOverview: React.FC<GameOverviewProps> = ({ game, propKey }) => {
  const { data } = useData(game.id, propKey);
  const { prop, isLoading } = useProp(propKey);
  const [selectedOutcome, setSelectedOutcome] = useState<string>(
    game.home_team
  );

  const mostRecentDataValue = useMemo(() => {
    const filteredData = selectedOutcome
      ? data.filter((d) => d.outcome_name === selectedOutcome)
      : data;

    return filteredData.length ? filteredData[filteredData.length - 1] : null;
  }, [data, selectedOutcome]);

  const openingDataValue = useMemo(() => {
    const filteredData = selectedOutcome
      ? data.filter((d) => d.outcome_name === selectedOutcome)
      : data;

    return filteredData.length ? filteredData[0] : null;
  }, [data, selectedOutcome]);

  const handleOnSelectOutcome = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOutcome(e.target.value);
  };

  if (isLoading) {
    return <>Loading...</>;
  } else if (!prop) {
    return (
      <ErrorPage
        title="An error occurred"
        description="Please refresh the page and try again."
      />
    );
  }

  return (
    <div className="flex flex-col md:flex-row gap-6 items-center justify-between border-b py-3 mb-2 border-gray-200">
      <Link to={`${PAGES.GAME}/${game.id}`} className="w-full md:w-auto">
        <div className="flex-col w-[250px] overflow-hidden hidden md:flex rounded-xl border relative border-gray-200">
          <div className="flex flex-col border-b py-3 border-gray-900/5 bg-gray-50">
            <p className="text-center font-medium">{game.away_team}</p>
            <p className="text-center">@</p>
            <p className="text-center font-medium">{game.home_team}</p>
          </div>
          <div className="divide-gray-100 px-4 py-2 text-sm bg-white">
            <div className="flex justify-between gap-x-4 text-gray-700">
              <p>{formatDate(new Date(game.start_time))}</p>
              <p>{formatTime(new Date(game.start_time))}</p>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full md:hidden text-white">
          <p className="text-lg font-semibold">
            {game.away_team} @ {game.home_team}
          </p>
          <div className="text-md flex gap-3">
            <p>{formatDate(new Date(game.start_time))}</p>
            <p>{formatTime(new Date(game.start_time))}</p>
          </div>
        </div>
      </Link>
      <div className="w-full md:w-auto md:grow flex gap-6">
        <div className="grow">
          <div className="w-full flex items-center justify-between">
            <div className="flex flex-col">
              <h3 className="text-white text-lg">
                {prop.label}{" "}
                {mostRecentDataValue && (
                  <PropData prop={prop} dataPoint={mostRecentDataValue} />
                )}
              </h3>
              {openingDataValue && (
                <h3 className="text-white text-sm italic">
                  Opening line{" "}
                  <PropData prop={prop} dataPoint={openingDataValue} />
                </h3>
              )}
            </div>
            <select
              value={selectedOutcome}
              onChange={handleOnSelectOutcome}
              className="block w-[300px] rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
            >
              <option value={game.home_team}>{game.home_team}</option>
              <option value={game.away_team}>{game.away_team}</option>
            </select>
          </div>
          <div className="py-4">
            <OverviewChart
              data={data}
              outcome_name={selectedOutcome}
              reverseColors={selectedOutcome !== game.home_team}
              plot_type={prop.primary_data}
            />
          </div>
        </div>
        <Link
          to={`${PAGES.GAME}/${game.id}`}
          className="cursor-pointer text-white hidden md:flex gap-1 items-center"
        >
          <p>View</p>
          <FiChevronRight />
        </Link>
      </div>
      <div className="flex md:hidden justify-end w-full">
        <Link
          to={`${PAGES.GAME}/${game.id}`}
          className="cursor-pointer flex text-white gap-1 items-center"
        >
          <p>View</p>
          <FiChevronRight />
        </Link>
      </div>
    </div>
  );
};

export default memo(GameOverview);
