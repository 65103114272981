import { useQuery } from "react-query";
import { QueryKey as PropsQueryKey, fetchProps } from "../api/props";
import { useMemo } from "react";
import { DataType } from "./useData";

export type Prop = {
  key: string;
  label: string;
  is_player_prop: boolean;
  sports?: string[] | null;
  primary_data: DataType;
  show_both_values: boolean;
  overValue?: number | null;
  order: number;
};

export const GAME_PROPS = {
  SPREAD_KEY: "spreads",
  MONEY_LINE_KEY: "h2h",
  TOTALS_KEY: "totals",
};

interface UsePropsInterface {
  allProps: Prop[];
  gameProps: Prop[];
  playerProps: Prop[];
  isLoading: boolean;
}

const useProps = (): UsePropsInterface => {
  const { isLoading, data } = useQuery(PropsQueryKey, fetchProps, {
    staleTime: Infinity,
  });

  const allProps = useMemo(() => data ?? [], [data]);

  const gameProps = useMemo(
    () => (data ? data.filter((d) => !d.is_player_prop) : []),
    [data]
  );
  const playerProps = useMemo(
    () => (data ? data.filter((d) => d.is_player_prop) : []),
    [data]
  );

  return {
    allProps,
    gameProps,
    playerProps,
    isLoading,
  };
};

export default useProps;
