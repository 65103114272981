import { Link } from "react-router-dom";
import { PAGES } from "../../../consts";
import useGame from "../../../hooks/useGame";
import { FiChevronLeft } from "react-icons/fi";
import PlayerPropComponent from "./PlayerProp";
import useProp from "../../../hooks/useProp";
import Redirect from "../../Redirect";
import ErrorPage from "../../Core/ErrorPage";

interface PlayerPropProps {
  gameId: string;
  propKey: string;
  outcome: string;
}

const PlayerProp: React.FC<PlayerPropProps> = ({
  gameId,
  propKey,
  outcome,
}) => {
  const { prop, isLoading: isLoadingProp } = useProp(propKey);
  const { game, isLoading: isLoadingGame } = useGame(gameId);

  if (isLoadingGame || isLoadingProp) {
    return <div>Loading</div>;
  } else if (!game) {
    return (
      <ErrorPage
        title="Game not found"
        description="Sorry, we couldn't find the game you're looking for."
      />
    );
  } else if (!prop) {
    return <Redirect to={PAGES.HOME} />;
  }

  return (
    <>
      <div className="absolute">
        <Link
          to={`${PAGES.GAME}/${gameId}/player`}
          className="cursor-pointer flex gap-1 items-center"
        >
          <FiChevronLeft />
          <p>Back</p>
        </Link>
      </div>
      <PlayerPropComponent game={game} prop={prop} outcome={outcome} />
    </>
  );
};

export default PlayerProp;
