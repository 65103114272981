import { useMemo } from "react";
import useProps, { Prop } from "./useProps";

interface UsePropInterface {
  prop?: Prop;
  isLoading: boolean;
}

const useProp = (propKey: string): UsePropInterface => {
  const { allProps, isLoading } = useProps();

  const prop = useMemo(
    () => allProps.find((p) => p.key === propKey),
    [allProps, propKey]
  );

  return {
    prop,
    isLoading,
  };
};

export default useProp;
