import { Link } from "react-router-dom";
import { PAGES } from "../../consts";
import useGame from "../../hooks/useGame";
import { formatDate, formatTime } from "../../utils/time";
import GameProp from "./GameProp";
import { FiChevronLeft } from "react-icons/fi";
import useProps, { GAME_PROPS, Prop } from "../../hooks/useProps";
import { useEffect, useState } from "react";
import ErrorPage from "../Core/ErrorPage";

interface GameProps {
  id: string;
}

const Game: React.FC<GameProps> = ({ id }) => {
  const { gameProps } = useProps();
  const { game, isLoading } = useGame(id);
  const [selectedProp, setSelectedProp] = useState<Prop | undefined>(undefined);

  const tabs = gameProps.map((p) => ({ key: p.key, name: p.label }));

  const handleSelectProp = (propKey: string) => {
    const foundProp = gameProps.find((gp) => gp.key === propKey);

    if (!foundProp) {
      alert("ERROR: prop not found. Please try again");
      return;
    }

    setSelectedProp(foundProp);
  };

  // Set default selected prop once gameProps load
  useEffect(() => {
    if (gameProps.length) {
      const moneyLineProp = gameProps.find(
        (gp) => gp.key === GAME_PROPS.MONEY_LINE_KEY
      );

      setSelectedProp(moneyLineProp ?? gameProps[0]);
    }
  }, [gameProps]);

  if (isLoading) {
    return <div>Loading</div>;
  } else if (!game) {
    return (
      <ErrorPage
        title="Game not found"
        description="Sorry, we couldn't find the game you're looking for."
      />
    );
  }

  return (
    <>
      <div className="absolute">
        <Link
          to={`${PAGES.HOME}`}
          className="cursor-pointer flex gap-1 items-center"
        >
          <FiChevronLeft />
          <p>Back</p>
        </Link>
      </div>
      <div className="flex flex-col w-full justify-center items-center mt-10">
        <p className="mb-3 text-xl font-semibold leading-6">
          {game.away_team} @ {game.home_team}
        </p>
        <div className="text-base flex gap-4 font-semibold leading-6">
          <p>{formatDate(new Date(game.start_time))}</p>
          <p>{formatTime(new Date(game.start_time))}</p>
        </div>
      </div>
      <div className="border-b border-white-200 pb-5 mb-3 sm:pb-0 mt-3 md:mt-0 w-full flex justify-between">
        <div>
          <nav className="-mb-px flex space-x-8">
            {tabs.map((tab) => (
              <div
                key={tab.name}
                className={`
                    ${
                      tab.key === selectedProp?.key
                        ? "border-indigo-500 text-indigo-600"
                        : "border-transparent text-gray-300 hover:border-gray-100 hover:text-gray-100"
                    }
                   cursor-pointer whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium`}
                aria-current={
                  tab.key === selectedProp?.key ? "page" : undefined
                }
                onClick={() => handleSelectProp(tab.key)}
              >
                {tab.name}
              </div>
            ))}
          </nav>
        </div>
        <div>
          <Link
            to={`${PAGES.GAME}/${id}/player`}
            className="-mb-px flex space-x-8 border-transparent text-gray-300 hover:border-gray-100 hover:text-gray-100 cursor-pointer whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium"
          >
            Player Props
          </Link>
        </div>
      </div>
      <div className="flex flex-col w-full px-0 md:px-10 gap-4">
        {selectedProp ? (
          <GameProp game={game} prop={selectedProp} />
        ) : (
          <>No prop selected.</>
        )}
      </div>
    </>
  );
};

export default Game;
